.peach-purple-gradient-text {
  background: -webkit-linear-gradient(left, #E263BF, #FFA06A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 14px;
}

.teal-green-gradient-text {
  background: -webkit-linear-gradient(left, #3FD1DA, #4AD758);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 14px;
}

.gradient-text-button {
  background-color: #1E1E21;
  border: none;
  padding: 14px 32px;
  border-radius: 8px;
  cursor: pointer;
}

.gradient-text-button:hover{
  background-color: #2a2a2e;
}

.gradient-text-button:active {
  background-color: #1e1e21;
}

.gradient-button-body {
  font-size: 14px; 
  font-weight: 500;
}