.parthean-img-size {
  height: 64px;
  margin-top: 36px;
}

.image-attribution {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color:  white;
  opacity: 0.5;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 36px 10px 0px 10px;
  width: 50vw;
}

.calculator-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-size: 24px;
  z-index: 2;
  width: 100%;
  font-weight: bold;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-self: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  margin: 0 0 36px 0;
}


.input-form[type=range] {
  -webkit-appearance: none;
  margin-bottom: 12px;
  background-color: black;
  width: 100%;
}
.input-form[type=range]::-webkit-slider-runnable-track {
  height: 8px;
  cursor: pointer;
  background: -webkit-linear-gradient(left, #3FD1DA, #4AD758);
  box-shadow: 1px 1px 1px #000000;
  border-radius: 5px;
  border: 1px solid #000000;
  opacity: 0.9;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 26px;
  width: 12px;
  border-radius: 5px;
  background: #FFFFFF;
  cursor: pointer;
  margin-top: -10px;
}

.calculated-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 24px;
  width: 100%;
}

.big-text {
  font-size: 48px;
  margin: 10px 0px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #E263BF, #FFA06A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
}

.footer-container {
  font-size: 14px;
  margin-top: 40px;
  font-weight: normal;
}