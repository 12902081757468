.get-started-button {
  border: none;
  color: #ffffff;
  background-color: #00781C;
  padding: 18px 24px;
  border-radius: 8px;
  cursor: pointer;
}

.get-started-button-text {
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 28px;
  font-family: Bebas Neue;
  color: white;
}

.get-started-button:hover {
  background-color: #0ea231;
}

.get-started-button:active {
  background-color: #006918;
}