.circle-button {
  box-shadow: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circle-button:hover {
  background-color: #ffffff;
}

.circle-button:active {
  background-color: gainsboro;
}

.circle-button-green {
  background-color: #13BA80;
}

.circle-button-green:hover {
  background-color: #1ace8f
}

.circle-button-green:active {
  background-color: #16aa76
}