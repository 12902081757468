@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Söhne-Buch.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Söhne-Kräftig.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url('./assets/fonts/Söhne-Halbfett.otf') format('opentype');
  font-weight: 700;
}


* {
  display: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Sohne';
  text-decoration: none;
}

html {
  width: 100%;
  height: 100%;
  background-color: black;
}

body {
  width: 100%;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}