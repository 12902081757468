.modal-container {
    width: 700px;
    max-width: 90vw;

    margin: auto;

    flex-direction: column;
    align-items: center;
    display: flex;
    border-radius: 12px;
    color: white;
}

.modal-img {
  height: 32px;
  margin-bottom: 36px;
  margin-top: 36px;
}

.modal-content {
  width: 100%;
}