.module-get-started-container {
  background-size: cover;
  /* height: 600px; */
  background-repeat: no-repeat;
}

.module-get-started-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: transparent;
  z-index: 1;
  background-color: #000000C9;
}

.module-get-started-header {
  font-weight: bold; 
  font-size: 32px; 
  margin-bottom: 32px;
  color: white;
}