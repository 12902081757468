.freetrial-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.freetrial-header {
  margin: 10px 10px 10px 0px;
  font-size: 48px;
  font-weight: 500;
  text-align: left;
}

.freetrial-colored-text {
  background: -webkit-linear-gradient(left, #3FD1DA , #4AD758);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.freetrial-deals {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  column-gap: 20px;
  
  /* display: flex; */
  /* flex-direction: row; */
  /* gap: 10px; */
  /* justify-content: flex-start; */
  /* align-items: center;
  width: 750px;
  max-width: 80vw;
  overflow-x: auto; */
}

.deal-card-img {
  width: 100%;
  
}

.deal-card-img:hover {
  cursor: pointer;
}

.freetrial-body {
  text-align: left;
  font-size: 16px;
  color: #9BA0A5;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 20px 0 0 0;
}
