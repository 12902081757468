.register-background {
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100vw;
    height: 100vh;
    overflow: hidden;

    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.78) 0%, #000000 100%), url(https://global-uploads.webflow.com/5e8176c1dad44d5cfe3067b4/62ab2137e890537da7769ea7_Image%20ID%3D5.png);

    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}


.signup-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 50px 42px;
  background: rgba(0, 28, 6, 0.6);
  border: 2px solid #005112;
  box-shadow: 10px 10px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}


.signedin-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.signedin-body {
  font-size: 15px;
}

.signedin-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.registration-form {
  display: flex;
  flex: 1;
  flex-direction:column;
}

.registration-error {
  font-size: 14px;
}

.registration-is-new-body {
  cursor: pointer;
  padding-top: 22px;
  font-size: 12px;
  text-decoration: uppercase;
  letter-spacing: 2px;
  color: white;
  text-decoration: underline;
}