.persona-title {
  color: white;
  margin: 10px 10px 10px 0px;
  font-size: 48px;
  text-align: left;
  font-weight: 500;
}

.persona-intro {
  color: white;
  margin: 10px 10px 10px 0px;
  font-size: 32px;
  text-align: left;
  font-weight: 400;
}

.persona-intro-colored-text {
  background: -webkit-linear-gradient(left, #E263BF, #FFA06A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.gradientColoredText {
  background: -webkit-linear-gradient(left, #E263BF, #FFA06A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.persona-info-container {
  background: #1E1E21;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;  
  padding: 3px;
  border-radius: 8px;
  width: 100%;

  margin-top: 22px;
}

.persona-image {
  background-color: #1E1E21;
  border-radius: 8px;
  width: 750px;
  max-width: 80vw;
}
