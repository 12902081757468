.quiz-container {
  display: flex;
  flex-direction: column;
  opacity: 0;
  max-width: 80vw;
  max-width: 100vh;
  color: white;
  margin: 20px 0;
}
.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.info-container {
  text-overflow: wrap;
  max-width: 40vw;
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.answer-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 60vw;
  width: 100%;
  border: solid white;
  border-radius: 10px;
  padding: 5px 20px;
  text-overflow: wrap;
  background-color: rgba(255, 255, 255, 0.1);
}
.answer-option:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.answer-option-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.answer-option-letter {
  border: solid white;
  border-radius: 10px;
  padding: 0px 12px;
}

.continue-button {
  align-self: 'flex-start';
  outline: none;
  border: none;
  background-color: #F1F2F3;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  padding: 12px 30px;
  max-width: 200px;
  margin-top: 40px;
  cursor: pointer;
  font-size: 24px;
  letter-spacing: 1px;
}
.continue-button:disabled {
  background-color: grey;
}
.get-started {
  color: #ffffff;
  background-color: #00781C;
  font-family: Bebas Neue;
}
