.video-container {
  /* height: 640px; */
  width: 300px;
  background-color: #202124;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}
.mux-video {
  flex: 1;
  width: 100%;
  aspect-ratio: auto;
  outline: none;
  overflow: auto;
  line-height: 0;
  object-fit: fill;
  align-self: center;
}

.video-subtitles-container {
  background-color: #202124;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 12px 16px 24px 16px;
  display: flex;
  flex-direction: column;
}

.video-progress-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}