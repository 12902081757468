.sign-up-button {
  outline: none;
  border: none;
  background-color: #F1F2F3;
  border-radius: 36px;
  color: black;
  font-weight: bold;
  padding: 12px 0px;
  margin-top: 4px;
  cursor: pointer;

  font-size: 14px;
  letter-spacing: 1px;
}

.sign-up-button:hover {
  background-color: #BEC1C5;
}

.sign-up-button:active {
  background-color: #BEC1C5;
}