

.coach-intro-container {
  margin: 10px;
  text-align: left;
}

.coach-intro-title {
  color: white;
  font-size: 48px;
  font-weight: 500;
  background: -webkit-linear-gradient(left, #E263BF, #FFA06A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.coach-intro-description {
  padding: 28px 0px;
  font-size: 18px;
}

.coach-bio-image {
  align-self: center;
  width: 750px;
  max-width: 80vw;
}