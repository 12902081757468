.mobile-appstore-button-container {
  margin-top: 22px;
}

.download-header {
  color: white;
  margin: 10px;
  font-size: 30px;
} 
 
.mobile-appstore-button-text {
  font-size: 14px;
  color: white; 
  margin-top: 32px;
}

.appstore-button-text {
  font-size: 14px;
  color: white;
}