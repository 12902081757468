.registration-text-input {
  background-color: black;
  color: #F1F2F3;
  border: none;
  border-radius: 6px;
  outline: none;
  padding: 16px;
  padding-left: 22px;
  margin-bottom: 14px;
  flex: 1;

  font-size: 16px;
  letter-spacing: 1.2px;


}

.registration-text-input::placeholder {
  color: #F1F2F3;
}

.registration-text-input:focus {
  outline: 1px solid #81868A;
}