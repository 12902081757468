.learning-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learning-title {
  color: white;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 500;
  text-align: left;
}

.learning-header-text {
  width: 75%;
  align-self: center;
  font-size: 14px;
}

.playbook-adder-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
  overflow-x: auto;
}

.playbook-image-container {
  display: flex;
  border-radius: 5px;
  cursor: pointer;
}

.playbook-image {
  object-fit: contain;
  height: 290px;
  /* width: 30vw; */
  /* height: 30vw; */
  /* max-width: 30vw; */
  /* min-width: 30vw; */
}


.learning-info-container {
  background: linear-gradient(to bottom right, #E263BF, #FFA06A);
  padding: 3px;
  border-radius: 8px;
  margin: 12px 0px 36px 0px;
  width: 750px;
  max-width: 80vw;
  align-self: center;  
}

.learning-info {
  background-color: #1E1E21;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  max-width: 80vw;
}

.info-header {
  font-size: 20px;
  margin: -15px 0 20px 0;
}

.user-playbooks-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0 0 10px 0;
  margin: 0 0 -15px 0;
  max-width: 100%;
  overflow-x: auto;
}

.playbook-container {
  background: linear-gradient(to bottom right, #E263BF, #FFA06A);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  min-width: 200px;
  max-width: 200px;
  height: 50px;
  border-radius: 5px;
  padding: 10px;
}

.playbook-title {
  font-weight: 600;
  font-size: 18px;
}

.confirm-playbooks-button {
  margin-top: 20px;
  width: 300px;
  background-color: white;
  border: none;
  padding: 14px 32px;
  border-radius: 32px;
  cursor: pointer;
}
.confirm-playbooks-button:hover{
  background-color: #BEC1C5;
}
.confirm-playbooks-button:active {
  background-color: #BEC1C5;
}
.confirm-playbooks-button:disabled {
  background-color: #9BA0A5;
  color: #3D4043;
}

.confirm-playbooks-text {
  font-size: 22px;
  font-family: Bebas Neue;
}